export const keys = {
    routeMethod: "routeMethod",
    favorites: "favorites",
    searches: "searches",
    user: "user",
    hotDeals: "hotDeals",
    headerReady: "headerReady",
    loginModalVisible: "loginModalVisible",
    isMobile: "isMobile"
}
class Store {
    _store = {
        loginModalVisible: false,
        headerReady: false,
        user: undefined,
        hotDeals: {
            page: 1,
            initial: true,
            sort: "stars:desc",
            pointsMin: 20,
            pointsMax: 1600,
            withSold: true,
            time: 7,
            useMonth: [],
            resorts: []
        },
        loading: true
    }
    k = keys

    /**
     * @param key {string}
     * @param value
     */
    set(key, value) {
        this._store = {
            ...this._store,
            [key]: value
        }
        if (this._watchers[key]) {
            const callbacks = Object.values(this._watchers[key])
            callbacks.forEach((cb) => cb(this._store[key]))
        }
        const allCb = Object.values(this._watchers["*"])
        allCb.forEach((cb) => cb(this._store))
    }

    /**
     * @param key {string}
     * @return {*}
     */
    get(key) {
        return this._store[key]
    }

    _getId = (() => {
        let i = 0
        return () => i++
    })()
    /**
     * @type {{}}
     * @private
     */
    _watchers = {
        "*": {}
    }

    /**
     * @param key {string}
     * @param callback {function(newValue: any)}
     * @return {function(): void}
     */
    watch(key, callback) {
        const id = this._getId()
        if (!this._watchers[key]) {
            this._watchers[key] = {}
        }
        this._watchers[key][id] = callback
        return () => {
            delete this._watchers[key][id]
        }
    }

    watchAll(cb) {
        const id = this._getId()
        this._watchers["*"][id] = cb
        return () => delete this._watchers["*"][id]
    }

    getAll() {
        return this._store
    }
}

export const store = new Store()
